<template>
    <div>
        <div class="main">
            <div class="top">
                <img src="../assets/image/title.png" alt="">
            </div>

            <div class="column">
                <div class="column-item">
                    <div class="title">
                        <img src="../assets/image/icon.png" alt="" srcset="">
                    </div>
                    <div class="desic font-color-wirte">
                        历史记录云端同步
                    </div>
                </div>
                <!-- <div class="placeholder"></div> -->
                <div class="column-item">
                    <div class="title">
                        <img src="../assets/image/icon.png" alt="" srcset="">
                    </div>
                    <div class="desic font-color-wirte">
                        历史记录云端同步
                    </div>
                </div>
                <!-- <div class="placeholder"></div> -->
                <div class="column-item">
                    <div class="title">
                        <img src="../assets/image/icon.png" alt="" srcset="">
                    </div>
                    <div class="desic font-color-wirte">
                        历史记录云端同步
                    </div>
                </div>
            </div>

          <div class="footer">
            <div class="ewm">
                <img src="../assets/image/ewm.png" alt="">
            </div>

            <div class="ewm-desic">
                <div class="name">请使用</div>
                <div class="wechart-icon">
                    <img src="../assets/image/wechart.png" alt="">
                </div>
                <div class="name">扫码登录/注册</div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
        return {

        }
    }
  }
</script>

<style lang="less" scoped>
    @import './css/login.less';
</style>